import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { doc, runTransaction, setDoc } from 'firebase/firestore'
import { useSnackbar } from 'notistack'
import DiscordWebhook from 'discord-webhook-ts';

import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popper,
  Typography,
} from '@mui/material'
import {
  MoreHoriz,
  BookmarkAddOutlined,
  BookmarkRemoveOutlined,
  Clear,
  Edit,
  Flag,
  AddCircle,
  RemoveCircle,
} from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { isOneDayPassed } from '@utils/isOneDayPassed'
import { MenuBox } from '@ui/ThemeBox'

import { IPost, IUser } from 'src/types/types'

type Props = {
}

export const ProfileMenu: FC<Props> = ({
}) => {
  const { t } = useTranslation(['news'])
  const { db } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { users } = useAppSelector((state) => state.users)
  const { emoji, uid, displayName, photoURL, blocking, blocked } = useAppSelector(
    (state) => state.user
  )

  const profileId = window.location.pathname.replace('/profile/', '')
  const user = users.find((user: IUser) => user.uid === profileId)

  const discordClient = new DiscordWebhook('https://discord.com/api/webhooks/1276615980809982034/F29z3JSzHoodXwN4_B7L_ET1RaPiHwemVWTMFwI3adAUbzS-fcFnWhn1_cPP_v-PaARe');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }


  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleReport = async (post: IUser) => {
    discordClient.execute({
      embeds: [
          {
              title: 'New User Report',
              description: "User: **" + post.displayName + "**",
              fields: [
                {
                  name: 'User ID',
                  value: post.uid,
                },
                {
                  name: 'Bio',
                  value: post.bio,
                },
            ],
            image: {
              url: post.photoURL,
            }
          },
      ]
  }).then((response) => {
      console.log('Successfully sent webhook.')
      enqueueSnackbar(t('Post reported!'), { variant: 'error' })
  })
  }

  const handleBlock = async () => {
    if (!uid) return
    const docRef = doc(db, 'users', profileId)
    const curRef = doc(db, 'users', uid)

    try {
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef)
        const sfCurDoc = await transaction.get(curRef)

        if (!sfDoc.exists()) {
          throw new Error('Document does not exist!')
        }

        const newFriendsArr = [
          ...new Map(
            [
              ...sfDoc.data().blocked,
              { displayName, photoURL, uid, emoji },
            ].map((user) => [user['uid'], user])
          ).values(),
        ]

        transaction.update(docRef, {
          blocked: newFriendsArr,
        })

        if (!sfCurDoc.exists()) {
          throw new Error('Document does not exist!')
        }

        const newFriendsArrCur = [
          ...new Map(
            [
              ...sfCurDoc.data().blocking,
              {
                displayName: sfDoc.data().displayName,
                photoURL: sfDoc.data().photoURL,
                uid: sfDoc.data().uid,
                emoji: sfDoc.data().emoji,
              },
            ].map((user) => [user['uid'], user])
          ).values(),
        ]

        transaction.update(curRef, {
          blocking: newFriendsArrCur,
        })

        enqueueSnackbar(t('User Blocked'), {
          preventDuplicate: true,
          variant: 'success',
        })
      })
    } catch (e) {
      console.log('Block failed: ', e)
    }
  }

  const handleUnblock = async () => {
    if (!uid) return
    const docRef = doc(db, 'users', profileId)
    const curRef = doc(db, 'users', uid)

    try {
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef)
        const sfCurDoc = await transaction.get(curRef)

        if (!sfDoc.exists()) {
          throw new Error('Document does not exist!')
        }

        const newFriendsArr = sfDoc
          .data()
          .blocked.filter((user: IUser) => user.uid !== uid)

        transaction.update(docRef, {
          blocked: newFriendsArr,
        })

        if (!sfCurDoc.exists()) {
          throw new Error('Document does not exist!')
        }

        const newFriendsArrCur = sfCurDoc
          .data()
          .blocking.filter((user: IUser) => user.uid !== sfDoc.data().uid)

        transaction.update(curRef, {
          blocking: newFriendsArrCur,
        })

        enqueueSnackbar(t('Follow Removed'), {
          preventDuplicate: true,
          variant: 'error',
        })
      })
    } catch (e) {
      console.log('Remove follow failed: ', e)
    }
  }

  return (
    <Box onMouseOver={handleToggle} onMouseOut={handleToggle}>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color="secondary"
        sx={{ width: '40px ', height: '40px', m: -1 }}
      >
        <MoreHoriz color="inherit" />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <MenuBox>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {user?.uid && 
                  <MenuItem onClick={() => handleReport(user)}>
                      <ListItemIcon sx={{ ml: -0.5, mr: -0.5 }}>
                        <Flag color="error" />
                      </ListItemIcon>
                      <Typography>{t('Report')}</Typography>
                    </MenuItem>
                  }
                  
                </MenuList>
              </ClickAwayListener>
            </MenuBox>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
