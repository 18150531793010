import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { updateProfile } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'
import { useSnackbar } from 'notistack'

import { Box, Stack, Typography } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { moveFocusAtEnd } from '@utils/moveFocusAtEnd'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeProfileAvatar } from '@ui/ThemeAvatar'
import { ThemeTextFieldAddPost } from '@ui/ThemeTextField'
import { ThemeSmallButton } from '@ui/ThemeButton'

import { PhotoMenu } from './components/PhotoMenu'
import { BannerMenu } from './components/BannerMenu'

export const ProfileSettings: FC = () => {
  const { t } = useTranslation(['settings'])
  const { db, ga } = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  document.title = t('Settings')

  const { uid, displayName, bannerURL, photoURL, link, emoji, bio } = useAppSelector(
    (state) => state.user
  )

  const [userName, setUserName] = useState<string>(displayName || '')
  const [linkus, setlink] = useState<string>(link || "")
  const [biO, setbio] = useState<string>(bio || '')

  const handleUpdateProfile = async () => {
    if (!uid || !ga.currentUser) return
    if (userName.trim().length === 0) {
      enqueueSnackbar(t('Enter at least one character'), { variant: 'error' })
      return
    }
    
    await updateProfile(ga.currentUser, { displayName: userName.trim() })

    const docRef = doc(db, 'users', uid)
    await setDoc(docRef, { displayName: userName.trim(), bio: biO, link: linkus }, { merge: true })

    enqueueSnackbar(t('Profile has been updated'), { variant: 'success' })
    navigate(`/profile/${uid}`)
  }

  const handleCancel = () => {
    navigate(`/profile/${uid}`)
  }

  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
      <Box sx={{ width: "100%", height: '230px' }} key={bannerURL}>
        <img
          src={bannerURL || 'https://firebasestorage.googleapis.com/v0/b/talkie-e6a42.appspot.com/o/FCMImages%2FTALKIE%20(1).png?alt=media&token=226837f6-f6db-4ece-bd33-ecc93aa97afa'}
          height='200px'
          width="100%"
          className="cover"
          loading="lazy"
          draggable={false}
        />
        <BannerMenu />
      </Box>
      <Stack alignItems="center" spacing={3}>
        <Box>
          <ThemeProfileAvatar
            alt={displayName || ''}
            src={photoURL || ''}
            draggable={false}
          >
            <Typography variant="h2">{emoji}</Typography>
          </ThemeProfileAvatar>
          <PhotoMenu />
        </Box>
        <ThemeTextFieldAddPost
          label={<b>{t('Name')}</b>}
          fullWidth
          autoComplete="off"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          onFocus={moveFocusAtEnd}
          inputProps={{ maxLength: 30 }}
          sx={{ maxWidth: '400px' }}
        />
        <ThemeTextFieldAddPost
          label={<b>{t(`Bio`)}</b>}
          fullWidth
          multiline
          inputProps={{ maxLength: 128 }}
          autoComplete="off"
          value={biO}
          onChange={(e) => setbio(e.target.value)}
          onFocus={moveFocusAtEnd}
          sx={{ mx: 2 }}
        />
        <ThemeTextFieldAddPost
          label={<b>{t(`Link`)}</b>}
          fullWidth
          inputProps={{ maxLength: 120 }}
          autoComplete="off"
          value={linkus}
          onChange={(e) => setlink(e.target.value)}
          onFocus={moveFocusAtEnd}
          sx={{ mx: 1 }}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <ThemeSmallButton startIcon={<Clear />} onClick={handleCancel}>
            {t('Cancel')}
          </ThemeSmallButton>
          <ThemeSmallButton startIcon={<Check />} onClick={handleUpdateProfile}>
            {t('Save')}
          </ThemeSmallButton>
        </Stack>
      </Stack>
    </BorderBox>
  )
}
