import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { stat } from 'fs'

import { IUserState, IUser } from 'src/types/types'

const initialState = {
  bookmarks: null,
  createdAt: null,
  displayName: null,
  emoji: null,
  followers: null,
  following: null,
  groups: null,
  music: null,
  photoURL: null,
  bannerURL: null,
  images: null,
  uid: null,
  isAuth: false,
  verified: false,
  bio: null,
  affiliate: false,
  banned: false,
  oac: false,
  live: null,
  link: null,
} as IUserState

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser>) {
      state.bookmarks = action.payload.bookmarks
      state.createdAt = action.payload.createdAt
      state.displayName = action.payload.displayName
      state.emoji = action.payload.emoji
      state.followers = action.payload.followers
      state.following = action.payload.following
      state.groups = action.payload.groups
      state.music = action.payload.music
      state.photoURL = action.payload.photoURL
      state.bannerURL = action.payload.bannerURL
      state.images = action.payload.images
      state.uid = action.payload.uid
      state.isAuth = action.payload.isAuth
      state.verified = action.payload.verified
      state.bio = action.payload.bio
      state.affiliate = action.payload.affiliate
      state.banned = action.payload.banned
      state.oac = action.payload.oac
      state.live = action.payload.live
      state.link = action.payload.link
    },

    removeUser(state) {
      state.bookmarks = null
      state.createdAt = null
      state.displayName = null
      state.emoji = null
      state.followers = null
      state.following = null
      state.groups = null
      state.music = null
      state.photoURL = null
      state.bannerURL = null
      state.images = null
      state.uid = null
      state.isAuth = false
      state.verified = false
      state.bio = null
      state.affiliate = false
      state.banned = false
      state.oac = false
      state.live = null
      state.link = null
    },
  },
})

export const { setUser, removeUser } = userSlice.actions

export const userReducer = userSlice.reducer
