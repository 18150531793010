import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { loadStripe } from "@stripe/stripe-js";

import { Box, Link, Typography } from '@mui/material'
import { Message, Paid, PersonAddAlt1 } from '@mui/icons-material'

import logo from '@assets/images/logo7.png'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeButton, ThemeSmallButton } from '@ui/ThemeButton'

import { builtWithList } from './builtWithList'

import { IUser } from 'src/types/types'
import { useAppSelector } from '@hooks/redux'
import { addDoc, doc, setDoc } from 'firebase/firestore'
import { useAuth } from '@hooks/useAuth'
import { generateUniqueId } from '@utils/generateUniqueId'

type Props = {
  user: IUser | undefined
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

export async function handlePayment(userId: string) {
  const stripe = await stripePromise;
  if (!stripe) return;

  const response = await fetch("https://api.stripe.com/v1/checkout/sessions", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY!}`, // ⚠️ NEPOUŽÍVEJ VE FRONTENDU NA PRODUKCI!
    },
    body: new URLSearchParams({
      "payment_method_types[]": "card",
      mode: "payment",
      success_url: window.location.origin + "/payment/success?session_id={CHECKOUT_SESSION_ID}&userId=" + userId,
      cancel_url: window.location.origin + "/payment/cancel",
      "line_items[0][price_data][currency]": "usd",
      "line_items[0][price_data][product_data][name]": "Talkie +",
      "line_items[0][price_data][unit_amount]": "199",
      "line_items[0][quantity]": "1",
    }),
  });
  
  // ✅ Debug odpovědi
  const session = await response.json();
  console.log("Stripe API response:", session);
  
  if (!session.id) {
    console.error("❌ Chyba: Stripe nevrátil sessionId!", session.error);
    return;
  }
  
  await stripe.redirectToCheckout({ sessionId: session.id });
  
}

export const Affiliate: FC = () => {
  const { t } = useTranslation(['affiliate'])
  document.title = "Talkie +"
  const { affiliate } = useAppSelector((state) => state.user)
  const { db } = useAuth()
  const { uid } = useAppSelector((state) => state.user)
  const { users } = useAppSelector((state) => state.users)
  const profileId = window.location.pathname.replace('/profile/', '')
  const user = users.find((user: IUser) => user.uid === uid)

  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={logo}
          alt="Talkie"
          height="150px"
          width="150px"
          draggable={false}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: '400', letterSpacing: 3, mb: 4 }}
        color="primary"
        textAlign="center"
      >
        {t('TALKIE +')}
      </Typography>
      <Typography>{t('Support us by joining Talkie + and get:')}</Typography>
      <ul>
        {builtWithList.map((item) => (
            <li>{t(item.title)}</li>
        ))}
      </ul>
      {user?.affiliate === false && (
        <ThemeButton   
          startIcon={<Paid />}
          onClick={() => handlePayment(user.uid)}
        >
          <b>{t('Join')}</b>
        </ThemeButton>
      )}
      {user?.affiliate === true && (
        <Typography
        variant="h5"
        textAlign="left"
        color="textSecondary"
        sx={{ my: 0 }}
      >
        <b>{t('Already Joined!')}</b>
      </Typography>
      )}
    </BorderBox>
  )
}
