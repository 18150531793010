import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { useSelector } from 'react-redux'

import { IPost, IPostsState, IUser } from 'src/types/types'

const initialState = {
  sortPostsBy: 'algorithm',
  posts: [],
} as IPostsState

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts(state, action: PayloadAction<IPost[]>) {
      if (state.sortPostsBy === 'algorithm') {
        const uidPriority = "uwC8FkdFDCZ0s8nuHn1Xm09aJsu2";
        const now = Date.now();
        const oneWeekMs = 604800000; // 7 days in milliseconds
        
          const getPriority = (a: WritableDraft<IPost>) => {
            let priority =
              (a.promoted ? 3 : 0) +
              (a.author.verified ? 2 : 0) +
              (now - +a.createdAt < 86400000 ? 4 : 0)+ 
              (now - +a.createdAt < 3600000 ? 10 : 0);;
  
            // Boost posts that are not older than one week
            if (now - +a.createdAt < oneWeekMs) {
              priority += 2;
            }
            return priority;
          };
  
          // Assign priorities and shuffle slightly before sorting
          state.posts = action.payload
            .map(post => ({ post, priority: getPriority(post) }))
            .sort((a, b) => b.priority - a.priority ) // Small randomization
            .map(entry => entry.post);
          
          // Separate posts into normal and prioritized groups
          let normalPosts = state.posts.filter(a => getPriority(a) <= 2);
          let prioritizedPosts = state.posts.filter(a => getPriority(a) > 2);
  
          // Add more randomness to normal posts selection (between 30% to 60%)
          const normalPostPercentage = 0.1 + Math.random() * 0.2; // Random value between 30% and 60%
          normalPosts = normalPosts.sort(() => Math.random() - 0.5).slice(0, Math.floor(normalPosts.length * normalPostPercentage));
  
          // Spread normal posts more evenly
          for (let i = 0; i < normalPosts.length; i++) {
            const insertIndex = Math.floor(Math.random() * (prioritizedPosts.length + 1));
            prioritizedPosts.splice(insertIndex, 0, normalPosts[i]);
          }
  
          state.posts = prioritizedPosts;
        
  }
  


      if (state.sortPostsBy === 'newest') {
        state.posts = action.payload.sort((a, b) => +b.createdAt - +a.createdAt)
      }

      if (state.sortPostsBy === 'oldest') {
        state.posts = action.payload.sort((a, b) => +a.createdAt - +b.createdAt)
      }

      if (state.sortPostsBy === 'likes') {
        state.posts = action.payload.sort(
          (a, b) => b.likes.length - a.likes.length
        )
      }

      if (state.sortPostsBy === 'controversial') {
        state.posts = action.payload.sort(
          (a, b) => b.comments.length - a.comments.length
        )
      }
    },
    

    removePosts(state) {
      state.posts = []
    },

    setPostsByAlgorithm(state, action: PayloadAction<{ user: IUser }>) {
      state.sortPostsBy = 'algorithm';
      const uidPriority = "uwC8FkdFDCZ0s8nuHn1Xm09aJsu2";
      const now = Date.now();
      const oneWeekMs = 604800000; // 7 days in milliseconds
      const { user } = action.payload; // Get users and current user UID from the action payload
    
      if (user) {
        const getPriority = (a: WritableDraft<IPost>) => {
          let priority =
            (a.promoted ? 2 : 0) +
            (a.author.verified ? 1 : 0) +
            (a.views.includes(user.uid) ? 0 : 3) +
            (now - +a.createdAt < 86400000 ? 4 : 0) + 
            (now - +a.createdAt < 3600000 ? 10 : 0);
    
          // Boost posts that are not older than one week
          if (now - +a.createdAt < oneWeekMs) {
            priority += 2;
          }
          return priority;
        };
    
        // Assign priorities without randomization
        state.posts = state.posts
          .map(post => ({ post, priority: getPriority(post) }))
          .sort((a, b) => b.priority - a.priority) // No randomization here
          .map(entry => entry.post);
    
        // Separate posts into normal and prioritized groups
        let normalPosts = state.posts.filter(a => getPriority(a) <= 4);
        let prioritizedPosts = state.posts.filter(a => getPriority(a) > 4);

                // Add more randomness to normal posts selection (between 30% to 60%)
                const normalPostPercentage = 0.1 + Math.random() * 0.2; // Random value between 30% and 60%
                normalPosts = normalPosts.sort(() => Math.random() - 0.5).slice(0, Math.floor(normalPosts.length * normalPostPercentage));
    
        // Spread normal posts more evenly without randomness
        for (let i = 0; i < normalPosts.length; i++) {
          const insertIndex = Math.floor(Math.random() * (prioritizedPosts.length + 1));
          prioritizedPosts.splice(insertIndex, 0, normalPosts[i]);
        }
    
        state.posts = prioritizedPosts;
      }
    },
    

    setPostsByNewest(state) {
      state.sortPostsBy = 'newest'
      state.posts = state.posts.sort((a, b) => +b.createdAt - +a.createdAt)
    },

    setPostsByOldest(state) {
      state.sortPostsBy = 'oldest'
      state.posts = state.posts.sort((a, b) => +a.createdAt - +b.createdAt)
    },

    setPostsByLikes(state) {
      state.sortPostsBy = 'likes'
      state.posts = state.posts.sort((a, b) => b.likes.length - a.likes.length)
    },

    setPostsByControversial(state) {
      state.sortPostsBy = 'controversial'
      state.posts = state.posts.sort(
        (a, b) => b.comments.length - a.comments.length
      )
    },
  },
})

export const {
  setPosts,
  removePosts,
  setPostsByNewest,
  setPostsByOldest,
  setPostsByLikes,
  setPostsByControversial,
  setPostsByAlgorithm,
} = postsSlice.actions

export const postsReducer = postsSlice.reducer
