import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { doc, setDoc } from 'firebase/firestore'
import { useSnackbar } from 'notistack'

import { Box, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import { Check, Clear, Link, Send, TaskAlt } from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { moveFocusAtEnd } from '@utils/moveFocusAtEnd'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeProfileAvatar } from '@ui/ThemeAvatar'
import { ThemeTextFieldAddPost } from '@ui/ThemeTextField'
import { ThemeSmallButton } from '@ui/ThemeButton'
import logo from '@assets/images/logo7.png'

import { PhotoMenu } from './components/PhotoMenu'
import DiscordWebhook from 'discord-webhook-ts'
import { IUser } from 'src/types/types'

export const Suggest: FC = () => {
  const { t } = useTranslation(['verified'])
  const { db, ga } = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  document.title = "Suggest"

  const discordClient = new DiscordWebhook('https://discord.com/api/webhooks/1276615980809982034/F29z3JSzHoodXwN4_B7L_ET1RaPiHwemVWTMFwI3adAUbzS-fcFnWhn1_cPP_v-PaARe');

  const { uid } = useAppSelector((state) => state.user)

  const { users } = useAppSelector((state) => state.users)

  const user = users.find((user: IUser) => user.uid === uid)

  const [userName, setUserName] = useState<string>('')
  const [biO, setbio] = useState<string>('')


  const handleUpdateProfile = async (post: IUser) => {
    discordClient.execute({
      embeds: [
          {
              title: 'New Suggestion',
              description: "User: **" + post.displayName + "**",
              fields: [
                {
                  name: 'User ID',
                  value: post.uid,
                },
                {
                  name: 'Suggestion',
                  value: userName,
                },
                {
                  name: 'About',
                  value: biO,
                },
            ],
          },
      ]
  }).then((response) => {
      console.log('Successfully sent webhook.')
      enqueueSnackbar(t('Suggestion sent!'), { variant: 'success' })
  })
  }


  return (
    <BorderBox sx={{ p: 3, mb: 2  }}>
      <Stack alignItems="center" spacing={2}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={logo}
          alt="Talkie"
          height="150px"
          width="150px"
          draggable={false}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: '400', letterSpacing: 3, mb: 4 }}
        color="info"
        textAlign="center"
      >
        Suggest a feature
      </Typography>
      <Typography>Let us know what features you would like to see on Talkie.</Typography>

        <ThemeTextFieldAddPost
          label={<b>Feature</b>}
          fullWidth
          autoComplete="off"
          onChange={(e) => setUserName(e.target.value)}
          onFocus={moveFocusAtEnd}
          sx={{ maxWidth: '400px' }}
        />
        <ThemeTextFieldAddPost
          label={<b>Describe the feature more</b>}
          fullWidth
          autoComplete="off"
          onChange={(e) => setbio(e.target.value)}
          onFocus={moveFocusAtEnd}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          { user?.uid &&
          <ThemeSmallButton startIcon={<Send />} onClick={() => handleUpdateProfile(user)}>
            {t('Submit')}
          </ThemeSmallButton>
          }
        </Stack>
      </Stack>
    </BorderBox>
  )
}
