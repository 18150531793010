import { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { doc, setDoc } from 'firebase/firestore'

import { Box, IconButton, Stack, Typography } from '@mui/material'
import { Clear, Download, Send } from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { generateUniqueId } from '@utils/generateUniqueId'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeAvatar } from '@ui/ThemeAvatar'
import { ThemeTextFieldAddPost } from '@ui/ThemeTextField'

import { AddPhotos } from './AddPhotos'
import { AddVideo } from './AddVideo'
import { AddEmoji } from './AddEmoji'
import { ThemeSmallButton } from '@ui/ThemeButton'

export const DownloadApp: FC = () => {

    const navigate = useNavigate()

  const handleSettings = () => {
    navigate('/app')
  }

  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
      <Typography
        variant="h6"
        sx={{ fontWeight: '400', letterSpacing: 3, mb: 1 }}
        color="primary"
        textAlign="left"
      >
        TALKIE APP
      </Typography>
      <Typography textAlign="left"
      sx={{ mb: 1 }}>Stay connected like never before with a seamless and engaging social experience.</Typography>
      <ThemeSmallButton
        onClick={handleSettings}
      >
        <b>More</b>
      </ThemeSmallButton>
    </BorderBox>
  )
}
