import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Link, Stack, Typography } from '@mui/material'
import { Download, Message, Paid, PersonAddAlt1 } from '@mui/icons-material'

import logo from '@assets/images/logo7.png'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeButton, ThemeSmallButton } from '@ui/ThemeButton'

import { IUser } from 'src/types/types'
import { useAppSelector } from '@hooks/redux'
import { addDoc, doc, setDoc } from 'firebase/firestore'
import { useAuth } from '@hooks/useAuth'
import { generateUniqueId } from '@utils/generateUniqueId'
import { useNavigate } from 'react-router-dom'

type Props = {
  user: IUser | undefined
}


export const Rewind: FC = () => {
  const { t } = useTranslation(['affiliate'])
  document.title = "Talkie App"
  const navigate = useNavigate()
  const { affiliate } = useAppSelector((state) => state.user)
  const { db } = useAuth()
  const { uid } = useAppSelector((state) => state.user)
  const { users } = useAppSelector((state) => state.users)
  const profileId = window.location.pathname.replace('/profile/', '')
  const user = users.find((user: IUser) => user.uid === uid)

  const joinaffiliate = async () => {
    const apkUrl = 'https://firebasestorage.googleapis.com/v0/b/talkie-e6a42.appspot.com/o/FCMImages%2FTalkie.apk?alt=media&token=a3bab3e9-53ac-4646-bba1-231c59c182eb'; // Replace with your APK file's URL
    const fileName = 'Talkie.apk';
  
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = fileName;
  
    // Trigger the download
    link.click();
  
    return link; // Optional, keeps a reference to the link
  }

  return (
    <BorderBox sx={{ p: 3, mb: 2  }}>
      <Stack alignItems="center" spacing={2}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={logo}
          alt="Talkie"
          height="150px"
          width="150px"
          draggable={false}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: '400', letterSpacing: 3, mb: 4 }}
        color="primary"
        textAlign="center"
      >
        {t('TALKIE APP')}
      </Typography>
      <Typography textAlign="center">Stay connected like never before with a seamless and engaging social experience. Whether you're sharing your thoughts, or discovering new communities, this app makes every interaction effortless and enjoyable.</Typography>
        <ThemeButton   
          startIcon={<Download />}
          onClick={() => joinaffiliate()}
        >
          <b>{t('Download for Android')}</b>
        </ThemeButton>
        </Stack>
    </BorderBox>
  )
}
