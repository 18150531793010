import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { loadStripe } from "@stripe/stripe-js";

import { Box, Link, Typography } from '@mui/material'
import { Message, Paid, PersonAddAlt1 } from '@mui/icons-material'

import logo from '@assets/images/logo7.png'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeButton, ThemeSmallButton } from '@ui/ThemeButton'

import { builtWithList } from './builtWithList'

import { IUser } from 'src/types/types'
import { useAppSelector } from '@hooks/redux'
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { addDoc, doc, updateDoc } from 'firebase/firestore'
import { useAuth } from '@hooks/useAuth'
import { generateUniqueId } from '@utils/generateUniqueId'

type Props = {
  user: IUser | undefined
}


export const PaymentCancel: FC = () => {
  const { t } = useTranslation(['affiliate'])
  document.title = "Talkie +"
  
  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          src={logo}
          alt="Talkie"
          height="150px"
          width="150px"
          draggable={false}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: '400', letterSpacing: 3, mb: 4 }}
        color="error"
        textAlign="center"
      >
        Payment canceled!
      </Typography>
    </BorderBox>
  )
}
