import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip, Stack, Typography } from '@mui/material'
import {
  ExpandLess,
  ExpandMore,
  FavoriteBorder,
  Group,
  Person,
} from '@mui/icons-material'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import {
  setPostsByControversial,
  setPostsByNewest,
  setPostsByOldest,
  setPostsByLikes,
  setPostsByAlgorithm
} from '@reducers/PostsSlice'
import { IUser } from 'src/types/types'
import { useAuth } from '@hooks/useAuth'

type Props = {
  setNumberVisiblePosts: React.Dispatch<React.SetStateAction<number>>
}

export const NewsOrderBy: FC<Props> = ({ setNumberVisiblePosts }) => {
  const { t } = useTranslation(['other'])

  const { sortPostsBy } = useAppSelector((state) => state.posts)
  const { users } = useAppSelector((state) => state.users)
  const { uid } = useAppSelector((state) => state.user)
  const currentUser = users.find((user: IUser) => user.uid === uid)
  const dispatch = useAppDispatch()

  const handleSortPostsByAlgorithm = () => {
    setNumberVisiblePosts(3)
    if (currentUser !== undefined) {
      dispatch(setPostsByAlgorithm({ user: currentUser }))
    }
  }

  const handleSortPostsByNewest = () => {
    setNumberVisiblePosts(3)
    dispatch(setPostsByNewest())
  }

  const handleSortPostsByOldest = () => {
    setNumberVisiblePosts(3)
    dispatch(setPostsByOldest())
  }

  const handleSortPostsByLikes = () => {
    setNumberVisiblePosts(3)
    dispatch(setPostsByLikes())
  }

  const handleSortPostsByControversial = () => {
    setNumberVisiblePosts(3)
    dispatch(setPostsByControversial())
  }

  return (
    <Stack
      alignItems="center"
      direction="row"
      sx={{ flexWrap: 'wrap', gap: 2 }}
    >
      <Typography>{t('Sort by')}</Typography>
      <Chip
        label={t('For you')}
        icon={<Person />}
        color={sortPostsBy === 'algorithm' ? 'primary' : 'default'}
        onClick={handleSortPostsByAlgorithm}
      />
      <Chip
        label={t('Newest')}
        icon={<ExpandMore />}
        color={sortPostsBy === 'newest' ? 'primary' : 'default'}
        onClick={handleSortPostsByNewest}
      />
    </Stack>
  )
}
